import React from "react";

function getWindowDimension() {
    const {innerWidth: width} = window;

    if(width < 600) {
        return {viewPort : "xs"};
    } else if(width >= 600 && width < 900) {
        return {viewPort : "sm"};
    } else if(width >= 900 && width < 1200) {
        return {viewPort : "md"};
    } else if(width >= 1200 && width < 1536) {
        return {viewPort : "lg"};
    } else {
        return {viewPort : "xl"};
    }
}

export default function WindowDimensions() {
    const [windowDimensions, setWindowDimensions] = React.useState(getWindowDimension());

    React.useEffect(() => {
       function handleResize() {
           setWindowDimensions(getWindowDimension());
       }

       window.addEventListener("resize", handleResize);
       return () => window.removeEventListener("resize", handleResize);
    }, []);

    return windowDimensions;
}