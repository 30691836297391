import React, { useCallback, useImperativeHandle, useRef } from "react";

import { DataGrid } from "@mui/x-data-grid";
import { useNavigate } from "react-router";
import { logout } from '../../base/authGuard';
import * as CallCore from "../../hook/callCore";
import useWindowDimensions from "../../hook/useWindowDimension";

export const DDTable = (
    { path, columns, refs }
) => {
    const { viewPort } = useWindowDimensions();

    const [tableData, setTableData] = React.useState([]);
    const [pageSize, setPageSize] = React.useState(10);
    const [page, setPage] = React.useState(0);
    const [filter, setFilter] = React.useState();
    const [rowCount, setRowCount] = React.useState();
    
    const colRender = useRef(columns);
    const _columns = useRef(columns);
    const navigate = useNavigate();

    const fireDataChanged = useCallback(() => {
        const tableFilter = {filter: filter, page: page, pageSize: pageSize};
        CallCore.getTable("/" + path + "/lst.php", tableFilter, "").then((json) => {
            if (json.ret) {
                setRowCount(json.rowcount);
                setTableData(json.rows);                
            } else {
                logout();
                navigate('/login', { state: { msg: json.msg, error: true } });
            }

        });
    }, [filter, page, pageSize, navigate, path]);

    useImperativeHandle(refs, () => ({
        doFireDataChanged() {
            fireDataChanged();
        }
    }));

    React.useEffect(() => {
        fireDataChanged();
    }, [filter, page, pageSize, navigate, fireDataChanged]);

    const tableColumns = useCallback(() => {

        if (!_columns.current) {
            _columns.current = columns;
        }

        colRender.current = [];

        for (var col of _columns.current) {

            if (!col.viewData) {
                colRender.current.push(col);
            } else {
                const colView = col.viewData.find(view => view === viewPort);

                if (colView === viewPort) {
                    colRender.current.push(col);
                }
            }

        }

        return colRender.current;
    }, [columns, viewPort]);

    React.useEffect(() => {
        tableColumns();
    }, [viewPort, tableColumns]);

    const onFilterChange = React.useCallback((filterModel) => {            
        setFilter(filterModel);
    }, []);

    return (
        <DataGrid
            rows={tableData}
            rowCount={rowCount}
            columns={colRender.current}
            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
            page={page}
            onPageChange={(newPage) => setPage(+newPage)}            
            onFilterModelChange={onFilterChange}
            filterMode="server"
            disableSelectionOnClick={true}
            filterModel={filter}
            rowsPerPageOptions={[5, 10, 20]}
            pagination="true"
            paginationMode="server"
            pageSize={pageSize} />
    );
};