import React, {useEffect, useState} from 'react';
import {useForm} from "react-hook-form";
import {useParams} from "react-router-dom";
import {useNavigate} from "react-router";
import {NavLink} from "react-router-dom";

import Alert from "@mui/material/Alert";
import Button from "@mui/material/Button";
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from "@mui/material/Link";
import Paper from "@mui/material/Paper";
import Typography from '@mui/material/Typography';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import * as CallCore from "../../hook/callCore";

import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

import {DDInputText} from "../../base/ddform/DDInputText";
import {logout} from '../../base/authGuard';
import './DetUser.scss';

const defaultValues = {
  id: "",
  dex: "",
  userLogin: "",
  expPasswd: "",
  oldPasswd: "",
  newPasswd: "",
  confPasswd: ""  
};

function DetUser() {

  const [alignment, setAlignment] = useState();
  const [msg, setMsg] = useState();
  const { id } = useParams();
  const navigate = useNavigate();

  const schema = Yup.object().shape({
    dex: Yup.string().trim().required("Campo obbligatorio").max(50, "Lunghezza massima possibile 50 caratteri"),
    userLogin: Yup.string().trim().required("Campo obbligatorio").max(50, "Lunghezza massima possibile 50 caratteri"),
    oldPasswd: Yup.string().trim().test("required", "Campo obbligatorio", (value) => {
      if(alignment === 'chgPasswd' && +id !== 0) {
        return value;
      } else {
        return true;
      }
    }),
    newPasswd: Yup.string().trim().test("required", "Campo obbligatorio", (value) => {
      if(alignment === 'chgPasswd') {
        return value;
      } else {
        return true;
      }         
    }).test("equal", "Le password non corrispondono", (value, context) => {
        if(alignment === 'chgPasswd') {
          const {confPasswd} = context.parent;
          return value === confPasswd;
        } else {
          return true;
        }
    }),
    confPasswd: Yup.string().trim().test("required", "Campo obbligatorio", (value) => {
      if(alignment === 'chgPasswd') {
        return value;
      } else {
        return true;
      }         
    }).test("equal", "Le password non corrispondono", (value, context) => {
      if(alignment === 'chgPasswd') {
        const {newPasswd} = context.parent;
        return value === newPasswd;
      } else {
        return true;
      }
    }),
  });
 
  const { handleSubmit, setValue, control } = useForm({ 
    defaultValues,  
    mode: "all",
    reValidateMode: "onChange",
    criteriaMode: "firstError",
    resolver: yupResolver(schema) 
  });

  const onChangePwd = (event, newAlignment) => {
    setAlignment(newAlignment);
  };

  const onSubmit = (data) =>  {
      CallCore.save("/user/save.php", data).then((json) => {
        if(json.ret) {
            navigate('/user');     
        } else if(json.code === 'session.expired') {
            logout();
            navigate('/login', { state: { msg: json.msg, error: true } });
        } else {
            setMsg(json.msg);
        }
      });
  };

  const DDBreadcrumbs = ({id}) => {
    return (
      <Breadcrumbs separator="›" aria-label="breadcrumb">
        <Link component={NavLink} underline="hover" key="1" color="inherit" to="/">
          Home
        </Link>,
        <Link
          component={NavLink}
          underline="hover"
          key="2"
          color="inherit"
          to="/user"      
        >
          Users
        </Link>,
        <Typography key="3" color="text.primary">          
          {id > 0 && <div>{id}</div>}
          {id === 0 && <div>New</div>}          
        </Typography>
      </Breadcrumbs>
    );
  }

  useEffect(() => {        
    if(id > 0) {
      CallCore.getDet("/user/det.php", "?id=" + id).then((json) => {
        if(json.ret) {
            const {id, dex, userlogin, exppasswd} = json.rows;
            setValue("id", id);
            setValue("dex", dex);
            setValue("userLogin", userlogin);        
            setValue("expPasswd", exppasswd);        
        } else {
            logout();
            navigate('/login', { state: { msg: json.msg, error: true } });
        }

      });
    } else {
      setAlignment("chgPasswd");      
    }
  }, [id, setValue, alignment, navigate]);

    return (
      <div className="detUser" style={{width: "100%"}}>
        {msg ? (<Alert severity="warning" sx={{marginTop: 2}}>{msg}</Alert>) : ""}        
        <DDBreadcrumbs id={+id}/>        
        <Paper
            component="form"
            sx={{
                 display: "flex",
                 flexDirection: "row",  
                 flexWrap: "wrap",       
                 rowGap: "24px",        
                 p: "20px",
                 m: "20px",
                 width: "100%"
             }}
        >                      
          <DDInputText name="id" control={control} label="ID" disabled={true} sx={{width: "40%"}}/>
          <DDInputText name="dex" control={control} label="Descrizione *"/>
          <DDInputText name="userLogin" control={control} label="Userlogin *" />
          <DDInputText name="expPasswd" control={control} label="Scadenza passwowrd" disabled={true} />
          <ToggleButtonGroup  
            sx={{flexGrow: 1}}            
            value={alignment}
            exclusive
            onChange={onChangePwd}
            aria-label="text chgPasswd"
            control={control}
          >
            <ToggleButton value="chgPasswd">
              Cambia password
            </ToggleButton>
          </ToggleButtonGroup>
          {+id !== 0 ? (<DDInputText name="oldPasswd" control={control} label="Password *" disabled={alignment !== 'chgPasswd'} type="password"/>) : ""}          
          <DDInputText name="newPasswd" control={control} label="Nuova password *" disabled={alignment !== 'chgPasswd'} type="password"/>
          <DDInputText name="confPasswd" control={control} label="Conferma password *" disabled={alignment !== 'chgPasswd'} type="password"/>
          <Button onClick={handleSubmit(onSubmit)} variant={"contained"} type={"submit"}>
            Salva
          </Button>
        </Paper>
    </div>
    );
}

export default DetUser;
