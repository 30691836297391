const baseUrl = process.env.REACT_APP_BASE_URL;

export async function getTable(path, tableFilter, params) {  
  var columnFilter = "";

  const {filter, page, pageSize} = tableFilter;

  if(filter?.items && filter?.items.length > 0) {      
      filter.items.forEach((item, idx) => {
        if(item.value != undefined) {

          if(columnFilter.length > 0) {
            columnFilter += "&";
          }

          columnFilter += "column=" + item.columnField + "&value=" + item.value + "&operator=" + item.operatorValue          
        }
      });      
  }
  
  if(columnFilter.length > 0) {
    columnFilter += "&";
  }

  columnFilter += "page=" + page + "&pageSize=" + pageSize;

  return fetch(
    baseUrl + path + "?" + columnFilter,
    {
        method: 'GET',
        headers: getHeaders()
    })
    .then((data) => data.json())
    .then((json) => {
      return json;
    });
}

export async function getDet(path, params) {

  return fetch(
    baseUrl + path + params,
    {
        method: 'GET',
        headers: getHeaders()
    })
    .then((data) => data.json())
    .then((json) => {
      return json;
    });
}

export async function getImg(path, params) {

  return fetch(
    baseUrl + path + params,
    {
        method: 'GET',
        headers: getHeadersBlob()
    })
    .then((data) => data.blob())
    .then((data) => {      
      return data;
    });
}

export async function save(path, data) {
  return fetch(
    baseUrl + path,
    {
        method: 'POST',
        body: JSON.stringify(data),
        headers: getHeaders()
    })
    .then((data) => data.json())
    .then((json) => {
      return json;
    });
}

export async function saveForm(path, data) {
  return fetch(
    baseUrl + path,
    {
        method: 'POST',
        body: data,
        headers: getHeadersFile()
    })
    .then((data) => data.json())
    .then((json) => {
      return json;
    });
}

export async function del(path, params) {

  return fetch(
    baseUrl + path + params,
    {
        method: 'GET',
        headers: getHeaders(),
    })
    .then((data) => data.json())
    .then((json) => {
        return json;
    });
}

export async function login(params) {
  return fetch(baseUrl + '/login.php', {
      method: 'POST',
      body: JSON.stringify(params),
      headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json'
      }
  })
  .then((response) => response.json())
  .then((json) => {
      return json;
  }).catch(err => err)
}

function getHeaders() {
    const auth = getJwt();
    const headers = {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    };

    if(auth) {
        headers.Authorization = "Bearer " + auth;
    }

    return headers;
}

function getHeadersFile() {
  const auth = getJwt();
  const headers = {
    'Accept': 'application/json'    
  };

  if(auth) {
      headers.Authorization = "Bearer " + auth;
  }

  return headers;
}

function getHeadersBlob() {
  const auth = getJwt();
  const headers = {
    'Accept': 'application/octet-stream'    
  };

  if(auth) {
      headers.Authorization = "Bearer " + auth;
  }

  return headers;
}

function getJwt() {
    const auth = localStorage.getItem("auth.jwt");

    if(auth) {
        return auth;
    } else {
        window.location.pathname = '/login';
    }
}
