import {
    Card,
    CardActionArea,
    CardContent,    
    Grid,        
    Typography
} from "@mui/material";

import CreditCardIcon from '@mui/icons-material/CreditCard';
import GroupIcon from '@mui/icons-material/Group';
import { Link } from "react-router-dom";

function Home() {
    return ( 
        <Grid container align="center" sx={{ display: "flex", flexDirection: "row", flexWrap: "wrap"}} spacing={2}>  
            <Grid item xs={12} md={6} lg={6} xl={6} component={Link} to="/user" key="User" sx={{textDecoration: "none"}}>
                <Card>
                    <CardActionArea>                    
                        <CardContent>
                            <Typography gutterBottom variant="h5" component="div">
                                <GroupIcon sx={{fontSize: 120}}/>
                            </Typography>
                            <Typography variant="h5" color="text.secondary">
                                Users
                            </Typography>
                        </CardContent>
                    </CardActionArea>
                </Card>                             
            </Grid>                  
            <Grid item xs={12} md={6} lg={6} xl={6} component={Link} to="/card" key="Card" sx={{textDecoration: "none"}}>    
                <Card>
                    <CardActionArea>                    
                        <CardContent>
                            <Typography gutterBottom variant="h5" component="div">
                                <CreditCardIcon sx={{fontSize: 120}}/>
                            </Typography>
                            <Typography variant="h5" color="text.secondary">
                                Card
                            </Typography>
                        </CardContent>
                    </CardActionArea>
                </Card>               
            </Grid>                                  
        </Grid>        
    );
};

export default Home;