import React from "react";
import {Routes, Route, NavLink} from "react-router-dom";
import { styled, useTheme } from '@mui/material/styles';
import {
    Box, Container, CssBaseline, Divider, Grid,
    IconButton, List, ListItem, Toolbar, Typography, Drawer
} from "@mui/material";
import MuiAppBar from '@mui/material/AppBar';
import {Menu, ChevronLeft, ChevronRight, AccountCircle} from "@mui/icons-material";

import Home from "../pages/Home";
import About from "../pages/About";
import ListUser from "../pages/user/ListUser";
import DetUser from "../pages/user/DetUser";
import ListCard from "../pages/card/ListCard";
import DetCard from "./card/DetCard";

import {logout} from "../base/authGuard";

import useWindowDimensions from "../hook/useWindowDimension";

const baseDev = process.env.REACT_APP_DEV;
const drawerWidth = 240;

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open, useview }) => ({
        flexGrow: 1,
        padding: theme.spacing(3),
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        marginLeft: `0px`,
        ...(open && (useview === 'xl' || useview === 'lg') && {
            width: `calc(100% - ${drawerWidth}px)`,
            marginLeft: `${drawerWidth}px`,
            transition: theme.transitions.create('margin', {
                easing: theme.transitions.easing.easeOut,
                duration: theme.transitions.duration.enteringScreen,
            })            
        }),
    }),
);

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open, useview }) => ({
    transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),    
    ...(open && (useview === 'xl' || useview === 'lg') && {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: `${drawerWidth}px`,
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),    
}));

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),    
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
}));

function doLogut() {
    logout();
    window.location.pathname = '/';
}

function Dashboard() {
    const { viewPort } = useWindowDimensions();
    const theme = useTheme();
    const [open, setOpen] = React.useState(false);
    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };
    
    function ViewPort() {
        if(process.env.REACT_APP_DEV === 'dev') {
            return viewPort;
        } else {
            return "";
        }
    }

    function Username() {
        const lsUser = localStorage.getItem("user");
        const jsUser = JSON.parse(lsUser);        
        return jsUser.dex;
    }

    const elem = (
        <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
            <CssBaseline enableColorScheme/>
            <AppBar position="fixed"
                    open={open || viewPort === 'xl' || viewPort === 'lg'}
                    useview={viewPort}>
                <Toolbar>
                    <Grid container direction="row" justifyContent="start" alignItems="center">
                        <Grid item sm={1} xs={1}>
                            <IconButton                                
                                color="inherit"
                                aria-label="open drawer"
                                onClick={handleDrawerOpen}
                                disabled={open || viewPort === 'xl' || viewPort === 'lg'}
                                edge="start"
                                sx={{ mr: 4 }}
                            >
                                <Menu />
                            </IconButton>
                        </Grid>
                        <Grid item sm={7} xs={10}>
                            <Typography variant="h6">
                                Gb-Card.it {baseDev === 'dev' ? <ViewPort /> : null}
                            </Typography>                        
                        </Grid>
                        <Grid item sm={4} xs={1}>
                            <Typography variant="h6" component="div" textAlign="right">
                            <IconButton sx={{color:"#fff"}}>
                                <AccountCircle />   
                            </IconButton> 
                            {
                                (viewPort === 'md' || viewPort === 'lg' || viewPort === 'xl') ? <Username/> : null
                            }                                                                      
                            </Typography>                            
                        </Grid>
                    </Grid>
                </Toolbar>
            </AppBar>                                        
            <Drawer
                sx={{
                    width: drawerWidth + 'px',
                    flexShrink: 0,
                    '& .MuiDrawer-paper': {
                        width: drawerWidth + 'px',
                        boxSizing: 'border-box',
                    },
                }}
                variant={(viewPort === 'xl' || viewPort === 'lg') ? "permanent" : "temporary"}
                anchor="left"
                open={open || viewPort === 'xl' || viewPort === 'lg'}
                onClick={open ? handleDrawerClose : null}>
                <DrawerHeader>
                    <IconButton onClick={handleDrawerClose} disabled={(viewPort === 'xl' || viewPort === 'lg')}>
                        {theme.direction === 'ltr' ? <ChevronLeft /> : <ChevronRight />}
                    </IconButton>
                    <Divider/>
                </DrawerHeader>
                <List>
                    <ListItem button={true} component={NavLink} to="/" key="Home" onClick={handleDrawerClose}>
                        Home
                    </ListItem>
                    <ListItem button={true} component={NavLink} to="/card" key="Card" onClick={handleDrawerClose}>
                        Card
                    </ListItem>
                    <ListItem button={true} component={NavLink} to="/user" key="Utenti" onClick={handleDrawerClose}>
                        Users
                    </ListItem>
                    <ListItem button={true} component={NavLink} to="/about" key="About" onClick={handleDrawerClose}>
                        About
                    </ListItem>
                    <ListItem button={true} onClick={doLogut}>
                        Logout
                    </ListItem>
                </List>
            </Drawer>                        
            <Main                
                open={open || viewPort === 'xl' || viewPort === 'lg'} 
                useview={viewPort}
                style={{ display: "flex", flexDirection: "column", height: "100%"}}>
                <DrawerHeader/>
                <Container style={{ display: "flex", flexDirection: "column", height: "100%"}} >
                    <Routes>
                        <Route path="/" element={<Home/>}/>
                        <Route path="/dashboard" element={<Home/>}/>
                        <Route path="/card" element={<ListCard/>}/>
                        <Route path="/about" element={<About />}/>
                        <Route path="/user" element={<ListUser />}/>
                        <Route path="/user/det/:id/" element={<DetUser />}/>
                        <Route path="/card/det/:id/" element={<DetCard />}/>
                    </Routes>
                </Container>
            </Main>
        </Box>
    );
    
    return elem;
};

export default Dashboard;