import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

export default class AskDialog extends Component {
    static propTypes = {
      open: PropTypes.bool.isRequired,
      onClose: PropTypes.func.isRequired
    };

    render() {
      const { onClose, open } = this.props;

      return (
        <div>
          <Dialog
            open={open}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              {"Attenzione!"}
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Sicuro di voler eliminare il record selezionato?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => {onClose(false);}}>Cancella</Button>
              <Button onClick={onClose} autoFocus>
                Ok
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      );
    }
}
