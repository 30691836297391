import React, {useEffect, useRef, useState} from 'react';
import {useForm} from "react-hook-form";
import {useParams} from "react-router-dom";
import {useNavigate} from "react-router";
import {NavLink} from "react-router-dom";

import Alert from "@mui/material/Alert";
import Button from "@mui/material/Button";
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from "@mui/material/Link";
import Paper from "@mui/material/Paper";
import Typography from '@mui/material/Typography';
import * as CallCore from "../../hook/callCore";

import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

import {DDInputText} from "../../base/ddform/DDInputText";
import {logout} from '../../base/authGuard';
import { Grid, IconButton, MenuItem, Tooltip, styled } from '@mui/material';
import { DDInputSelect } from '../../base/ddform/DDInputSelect';
import SaveIcon from '@mui/icons-material/Save';
import CloseIcon from '@mui/icons-material/Close';

import './DetCard.scss';
import { DDTextArea } from '../../base/ddform/DDTextArea';

const defaultValues = {
  id: "",
  cod: "",
  dex: "",
  dex2: "",
  nome: "",
  cognome: "",
  ruolo: "",
  frase: "",
  www: "",
  cel: "",
  tel: "",
  email: "" ,
  facebook: "",
  ig: "",
  map: "",
  linkedin: "",
  whatsapp: "",
  logo: "",
  template: "",
  link: "",
  datiazienda: ""
};

function DetCard() {  
  const { id } = useParams();

  const [msg, setMsg] = useState();
  const [file, setFile] = useState();
  const [img, setImg] = useState();
  const [cover, setCover] = useState();
  const inputRef = useRef();
  const coverRef = useRef();  
  const navigate = useNavigate();

  const schema = Yup.object().shape({
    dex: Yup.string().trim().required("Campo obbligatorio").max(50, "Lunghezza massima possibile 50 caratteri"),
    dex2: Yup.string().trim().required("Campo obbligatorio").max(100, "Lunghezza massima possibile 100 caratteri"),
    cod: Yup.string().trim().required("Campo obbligatorio").max(255, "Lunghezza massima possibile 50 caratteri"),
    frase: Yup.string().trim().max(100, "Lunghezza massima possibile 100 caratteri").nullable(),
    nome: Yup.string().trim().max(50, "Lunghezza massima possibile 50 caratteri").nullable(),
    cognome: Yup.string().trim().max(50, "Lunghezza massima possibile 50 caratteri").nullable(),
    ruolo: Yup.string().trim().max(50, "Lunghezza massima possibile 50 caratteri").nullable(),
    www: Yup.string().trim().max(255, "Lunghezza massima possibile 255 caratteri").nullable(),
    datiazienda: Yup.string().trim().max(255, "Lunghezza massima possibile 255 caratteri").nullable(),
    cel: Yup.string().trim().max(255, "Lunghezza massima possibile 255 caratteri"),
    tel: Yup.string().trim().max(255, "Lunghezza massima possibile 255 caratteri"),
    email: Yup.string().trim().max(255, "Lunghezza massima possibile 255 caratteri"),
    facebook: Yup.string().trim().max(255, "Lunghezza massima possibile 255 caratteri"),
    ig: Yup.string().trim().max(255, "Lunghezza massima possibile 255 caratteri"),
    map: Yup.string().trim().max(500, "Lunghezza massima possibile 255 caratteri"),
    linkedin: Yup.string().trim().max(255, "Lunghezza massima possibile 255 caratteri"),
    whatsapp: Yup.string().trim().max(255, "Lunghezza massima possibile 255 caratteri"),
    template: Yup.number().min(1, "Selezione non valida").max(3, "Selezione non valida")
  });
 
  const { handleSubmit, setValue, isDirty, control } = useForm({ 
    defaultValues,  
    mode: "all",
    reValidateMode: "onChange",
    criteriaMode: "firstError",
    resolver: yupResolver(schema) 
  });

  const onSubmit = (data) =>  {    
    const form = new FormData();    
    form.append("id", data.id);
    form.append("dex", data.dex);
    form.append("dex2", data.dex2);
    form.append("nome", data.nome);
    form.append("cognome", data.cognome);
    form.append("ruolo", data.ruolo);
    form.append("cod", data.cod);
    form.append("frase", data.frase);
    form.append("www", data.www);
    form.append("cel", data.cel);
    form.append("tel", data.tel);
    form.append("email", data.email);
    form.append("facebook", data.facebook);    
    form.append("template", data.template);
    form.append("ig", data.ig);    
    form.append("map", data.map);    
    form.append("linkedin", data.linkedin);    
    form.append("whatsapp", data.whatsapp);
    form.append("datiazienda", data.datiazienda);    
    form.append("file", file);
    form.append("cover", cover);

    CallCore.saveForm("/card/save.php", form).then((json) => {
      if(json.ret) {
          navigate('/card');     
      } else if(json.code === 'session.expired') {
          logout();
          navigate('/login', { state: { msg: json.msg, error: true } });
      } else {
          setMsg(json.msg);
      }
    });
  };

  const handleCloseClick = () => {
    navigate("/card");
  };

  const handleUploadClick = () => {
    inputRef.current?.click();
  };

  const handleUploadCoverClick = () => {
    coverRef.current?.click();
  };

  const handleFileChange = (e) => {   
    if (!e.target.files) {
      return;
    }

    setFile(e.target.files[0]);
  };

  const handleCoverChange = (e) => {   
    if (!e.target.files) {
      return;
    }

    setCover(e.target.files[0]);
  };

  const loadImage = async (id) => {    
    CallCore.getImg("/card/img.php", "?id=" + id).then((data) => {            
      setImg(URL.createObjectURL(data));
    });
  };

  const loadCover = async (id) => {    
    CallCore.getImg("/card/img.php", "?tipo=1&id=" + id).then((data) => {            
      setCover(URL.createObjectURL(data));
    });
  };

const options = [
  <MenuItem key="0" value={0}>...</MenuItem>,
  <MenuItem key="1" value={1}>Template standard - Blu</MenuItem>,
  <MenuItem key="3" value={2}>Template standard - Nero</MenuItem>,
  <MenuItem key="4" value={3}>Template persona - Blu</MenuItem>
];

  const DDBreadcrumbs = ({id}) => {
    return (
      <Breadcrumbs separator="›" aria-label="breadcrumb">
        <Link component={NavLink} underline="hover" key="1" color="inherit" to="/">
          Home
        </Link>,
        <Link
          component={NavLink}
          underline="hover"
          key="2"
          color="inherit"
          to="/card"      
        >
          Card
        </Link>,
        <Typography key="3" color="text.primary">          
          {id > 0 && <span>{id}</span>}
          {id === 0 && <span>New</span>}          
        </Typography>
      </Breadcrumbs>
    );
  }

  const Img = styled('img')({
    margin: 'auto',
    display: 'block',
    maxWidth: '100%',
    maxHeight: '100%',
  });

  useEffect(() => {        
    if(id > 0) {
      CallCore.getDet("/card/det.php", "?id=" + id).then((json) => {
        if(json.ret) {

            const {
              id, dex, dex2, cod, frase, www, cel, tel, email, 
              facebook, template, ig, map, linkedin, whatsapp,
              cognome, nome, ruolo, link, datiazienda
            } = json.rows;            

            setValue("id", id);
            setValue("dex", dex);
            setValue("dex2", dex2 ? dex2 : "");
            setValue("cod", cod);        
            setValue("frase", frase ? frase : "");  
            setValue("nome", nome ? nome : "");        
            setValue("cognome", cognome ? cognome : "");        
            setValue("ruolo", ruolo ? ruolo : "");        
            setValue("www", www ? www : "");        
            setValue("cel", cel ? cel : "");
            setValue("tel", tel ? tel : "");
            setValue("email", email ? email : "");
            setValue("facebook", facebook ? facebook : "");
            setValue("template", template ? template : "");
            setValue("ig", ig ? ig : "");
            setValue("map", map ? map : "");
            setValue("linkedin", linkedin ? linkedin : "");
            setValue("whatsapp", whatsapp ? whatsapp : "");
            setValue("link", link ? link : "");
            setValue("datiazienda", datiazienda ? datiazienda : "");
            loadImage(id);
            loadCover(id);                                    
        } else {
            logout();
            navigate('/login', { state: { msg: json.msg, error: true } });
        }

      });
    }
  }, [id, setValue, navigate]);

    return (
      <div className="detCard" style={{width: "100%"}}>
        {msg ? (<Alert severity="warning" sx={{marginTop: 2}}>{msg}</Alert>) : ""}        
        <DDBreadcrumbs id={+id}/>        
        <Grid container align="right" sx={{ display: "flex", flexDirection: "row-reverse"}}>  
            <Tooltip title="Chiudi">
              <IconButton color="primary" aria-label="chiudi" onClick={handleCloseClick}>
                <CloseIcon /> 
              </IconButton>
            </Tooltip>
            <Tooltip title="Salva">
              <IconButton color="primary" aria-label="save" onClick={handleSubmit(onSubmit)} disabled={isDirty}>
                <SaveIcon /> 
              </IconButton>
            </Tooltip>            
        </Grid>
        <Paper            
            component="form"
            sx={{
                display: "flex",
                flexDirection: "row",  
                flexWrap: "wrap",                         
                rowGap: "24px",        
                p: "20px",
                m: "20px",
                width: "100%"
            }}
        >       
          <Grid container spacing={4}>
            <Grid item xs={6} md={4}>
              <DDInputText name="id" control={control} label="ID" disabled={true}/>
            </Grid>
            <Grid item xs={12} md={8}>
              <DDInputText name="cod" control={control} label="Codice *"/>
            </Grid>
          </Grid>
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <DDInputText name="dex" control={control} label="Nome azienda *"/>            
            </Grid>
            <Grid item xs={12}>
              <DDInputText name="dex2" control={control} label="Descrizione *"/>            
            </Grid>            
            <Grid item xs={12}>
              <DDInputText name="frase" control={control} label="Motto"/>
            </Grid>
            <Grid item xs={12} md={6}>
              <DDInputText name="nome" control={control} label="Nome"/>            
            </Grid>
            <Grid item xs={12} md={6}>
              <DDInputText name="cognome" control={control} label="Cognome"/>            
            </Grid>
            <Grid item xs={12}>
              <DDInputText name="ruolo" control={control} label="Ruolo"/>
            </Grid>           
            <Grid item xs={12}>
              <DDInputText name="www" control={control} label="Sito"/>
            </Grid>
            <Grid item xs={12}>
              <DDInputText name="facebook" control={control} label="Facebook"/>   
            </Grid>
            <Grid item xs={12}>
              <DDInputText name="ig" control={control} label="Instagram"/>          
            </Grid>
            <Grid item xs={12}>
              <DDInputText name="map" control={control} label="Google Maps"/>          
            </Grid>
            <Grid item xs={12}>
              <DDInputText name="linkedin" control={control} label="Linkedin"/>          
            </Grid>
            <Grid item xs={12}>
              <DDInputText name="whatsapp" control={control} label="Whatsapp"/>          
            </Grid>
            <Grid item xs={12}>
              <DDInputText name="tel" control={control} label="Telefono"/>          
            </Grid>
            <Grid item xs={12}>
              <DDInputText name="cel" control={control} label="Cellulare"/>          
            </Grid>
            <Grid item xs={12}>
              <DDInputText name="email" control={control} label="Email"/>              
            </Grid>
            <Grid item xs={12}>
              <DDTextArea name="datiazienda" control={control} label="Dati aziendali"/>              
            </Grid>
            <Grid item xs={12}>
              <DDInputSelect name="template" control={control} label="Template" options={options}/>  
            </Grid>
            <Grid item xs={12}>
              <DDInputText name="link" control={control} label="Link card" disabled={true}/>  
            </Grid>
          </Grid>               
          <Img 
            alt="Logo"
            src={img}
          />                          
          <Button onClick={handleUploadClick} variant={"contained"} type={"button"}>
            Upload foto profilo
          </Button>          
          <Img 
            alt="Cover"
            src={cover}
          />   
          <Button onClick={handleUploadCoverClick} variant={"contained"} type={"button"}>
            Upload copertina
          </Button>          
          <input
            type="file"
            ref={inputRef}
            onChange={handleFileChange}
            style={{ display: 'none' }}
          />
          <input
            type="file"
            ref={coverRef}
            onChange={handleCoverChange}
            style={{ display: 'none' }}
          />
          <Button onClick={handleSubmit(onSubmit)} variant={"contained"} type={"submit"}>
            Salva
          </Button>
        </Paper>          
      </div>
    );
}

export default DetCard;
