import React from "react";
import { Controller } from "react-hook-form";
import { TextField } from '@mui/material';

export const DDTextArea = ({ name, control, label, disabled = false, type = 'text', sx, onChange }) => {
  
    return (
        <Controller               
          name={name}
          control={control}
          render={({
            field: { onChange, value },
            fieldState: { error }            
          }) => (            
            <TextField
              sx={sx}
              multiline
              minRows={3}
              helperText={error ? error.message : null}
              size="small"
              error={!!error}
              onChange={onChange}
              value={value}
              fullWidth
              label={label}
              variant="standard"
              disabled={disabled}
              type={type}
            />            
          )}
    />
    );
};
