import React, { useRef } from 'react';
import {NavLink} from "react-router-dom";
import {
  Alert,
  Breadcrumbs,
  IconButton,
  Link,
  Typography
} from '@mui/material';

import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

import AskDialog from '../../base/askDialog';
import * as CallCore from "../../hook/callCore";
import { DDTable } from '../../base/ddtable/DDTable';

function ListCard() {     
    const tableRef = useRef();
    const [open, setOpen] = React.useState(false);
    const [delId, setDelId] = React.useState();
    const [error, setError] = React.useState();
    const [msg, setMsg] = React.useState();    

    const renderDetailsButton = (params) => {
      return (
        <div>
          <IconButton
            button="true"
            component={NavLink}
            to={"/user/det/" + params.row.id}
            color="primary"
            aria-label="Modifica">
              <EditIcon />
          </IconButton>
          <IconButton
            button="true"
            onClick={() => {
              setDelId(params.row.id);
              setOpen(true);
            }}
            color="primary"
            aria-label="Elimina">
              <DeleteIcon />
            </IconButton>
          </div>
      );
  }

  const columns = [
      { field: 'id', headerName: 'ID', type: 'number', hideable: false},
      { field: 'dex', headerName: 'Descrizione', flex: 50, type: 'string', hideable: false},
      { field: 'userlogin', headerName: 'Userlogin', flex: 50, type: 'string', hideable: false },
      { field: 'lastlogin', headerName: 'Ultimo accesso', flex: 50, filterable: false, hideable: false },
      {
          field: 'action', headerName: "Azioni", disableColumnMenu: true,
          renderCell: renderDetailsButton,
          align: 'right'
      }
  ];
    
  const DDBreadcrumbs = ({id}) => {
      return (
        <Breadcrumbs separator="›" aria-label="breadcrumb">
          <Link component={NavLink} underline="hover" key="1" color="inherit" to="/">
            Home
          </Link>,
          <Typography key="3" color="text.primary">
            Users
          </Typography>
        </Breadcrumbs>
      );
  }

    function deleteRow(id) {
      CallCore.del("/user/del.php", "?id=" + id).then((json) => {
        if(json.ret) {
            tableRef.current.doFireDataChanged();
        } else {
          setMsg(json.msg);
          setError(true);
        }
      });
    }

    return (
      <div className="App" style={{ display: "flex", flexDirection: "column", height: "100%"}}>
        {error ? (<Alert severity="warning" sx={{marginTop: 2, marginBottom: 2}}>{msg}</Alert>) : ""}
        <DDBreadcrumbs />
        <AskDialog open={open} onClose={(ret) => {
            setOpen(false);
            if(ret) {
                deleteRow(delId);
            } else {
              setMsg("Operazione annullata dall'utente");
              setError(true);
            }
          }
        } />
        <div className="App" style={{ display: "flex", flexDirection: "row-reverse"}}>
          <IconButton
            sx={{width: "40px"}}
              type="button"
              component={NavLink}
              to={"/user/det/0"}
              color="primary"
              aria-label="Nuovo">
                <AddIcon />
          </IconButton>
        </div>        
        <DDTable
            path="user"            
            refs={tableRef}
            columns={columns}
          />   
    </div>
  );
}

export default ListCard;
