import React, { useRef } from 'react';
import {NavLink} from "react-router-dom";
import {
  Alert,
  Breadcrumbs,
  IconButton,
  Link,
  Tooltip,
  Typography
} from '@mui/material';

import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import NoteAddIcon from '@mui/icons-material/NoteAdd';

import AskDialog from '../../base/askDialog';
import * as CallCore from "../../hook/callCore";

import {DDTable} from '../../base/ddtable/DDTable';

function ListCard() {   
    const tableRef = useRef();
    const [open, setOpen] = React.useState(false);
    const [delId, setDelId] = React.useState();
    const [error, setError] = React.useState();
    const [msg, setMsg] = React.useState();
    const [result, setResult] = React.useState();    

    const renderDetailsButton = (params) => {            
        return (
          <div>                    
            <Tooltip title="Genera pagina">
              <IconButton
                button="true"
                onClick={() => {              
                  setPage(params.row.id)
                }}
                color="primary"
                aria-label="Genera pagina">
                  <NoteAddIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Modifica">
              <IconButton
                button="true"
                component={NavLink}
                to={"/card/det/" + params.row.id}
                color="primary"
                aria-label="Modifica">
                  <EditIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Elimina">
              <IconButton
                button="true"
                onClick={() => {
                  setDelId(params.row.id);
                  setOpen(true);
                }}
                color="primary"
                aria-label="Elimina">
                  <DeleteIcon />
              </IconButton>
            </Tooltip>
            </div>
      );      
  }  

  const columns = [
      { field: 'id', headerName: 'ID', flex: 10, hideable: false, type: 'number',},
      { field: 'cod', headerName: 'Cod', flex: 50, viewData: ['md', 'lg', 'xl'], hideable: false },
      { field: 'dex', headerName: 'Descrizione', flex: 50, hideable: false },      
      { field: 'update_page', headerName: 'Agg.to pagina', flex: 50, viewData: ['lg', 'xl'], filterable: false, hideable: false },
      {
          field: 'action', headerName: "Azioni",
          disableColumnMenu: true, hideable: false,
          sortable: false, renderCell: renderDetailsButton,
          align: 'right', flex: 30
      }
  ];    
    
  const DDBreadcrumbs = ({id}) => {
      return (
        <Breadcrumbs separator="›" aria-label="breadcrumb">
          <Link component={NavLink} underline="hover" key="1" color="inherit" to="/">
            Home
          </Link>,
          <Typography key="3" color="text.primary">
            Card
          </Typography>
        </Breadcrumbs>
      );
    }

    function setPage(id) {
      CallCore.getDet("/card/generate.php", "?id=" + id).then((json) => {
        if(json.ret) {
          setResult("Operazione completata");
          tableRef.current.doFireDataChanged();
        } else {
          setMsg(json.msg);
          setError(true);
        }
      });
    }

    function deleteRow(id) {
      CallCore.del("/card/del.php", "?id=" + id).then((json) => {
        if(json.ret) {
          tableRef.current.doFireDataChanged();
        } else {
          setMsg(json.msg);
          setError(true);
        }
      });
    }

    return (
      <div className="App" style={{ display: "flex", flexDirection: "column", height: "100%", width: "100%"}}>
        {error ? (<Alert severity="warning" sx={{marginTop: 2, marginBottom: 2}}>{msg}</Alert>) : ""}
        {result ? (<Alert severity="success" sx={{marginTop: 2, marginBottom: 2}}>{result}</Alert>) : ""}
        <DDBreadcrumbs />
        <AskDialog open={open} onClose={(ret) => {
            setOpen(false);
            if(ret) {
                deleteRow(delId);
            } else {
              setMsg("Operazione annullata dall'utente");
              setError(true);
            }
          }
        } />
        <div className="App" style={{ display: "flex", flexDirection: "row-reverse"}}>
          <Tooltip title="Nuovo">
            <IconButton
              sx={{width: "40px"}}
                variant="outlined"
                type="button"
                component={NavLink}
                to={"/card/det/0"}
                color="primary"
                aria-label="Nuovo">
                  <AddIcon />
            </IconButton>
          </Tooltip>
          </div>
          <DDTable            
            path="card"
            columns={columns}
            refs={tableRef}
          />        
    </div>
  );

}

export default ListCard;