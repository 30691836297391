import React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { CardActionArea } from '@mui/material';
import logo from "../assets/gb-logo.png";

function About() {
  return (
    <div className="App" style={{ display: "flex", flexDirection: "column", height: "100%", alignItems: "center"}}>
        <Card sx={{ maxWidth: 400}}>
          <CardActionArea>
            <CardMedia
                sx={{ padding: "1em 1em 0 1em", objectFit: "contain" }}
                component="img"
                height="200"
                image={logo}
                alt="Gb communication"
            />
            <CardContent>
              <Typography gutterBottom variant="h5" component="div">
                GB Communication s.r.l.
              </Typography>
              <Typography variant="body2" color="text.secondary" component="div">
                Dai voce al tuo brand
              </Typography>
            </CardContent>
          </CardActionArea>
        </Card>
    </div>
  );
}

export default About;