import * as React from "react";
import {BrowserRouter as Router, Routes, Route} from "react-router-dom";
import ProtectedRoute from "./base/protectedRoute";
import Login from "./pages/Login";
import Dashboard from "./pages/Dashboard";

export default function App() {

    return (
        <Router>
            <Routes>
                <Route path="/login" exact element={<Login/>}/>
                <Route path="/login/:msg" exact element={<Login/>}/>
                <Route element={<ProtectedRoute/>}>
                    <Route path="/*" element={<Dashboard/>}/>
                </Route>
            </Routes>
        </Router>
    );
};