
export function isAuth() {
  const isAuth = localStorage.getItem("user");
  return isAuth !== null;
}

export function setAuth(user, jwt) {
  localStorage.setItem("user", JSON.stringify(user));  
  localStorage.setItem("auth.jwt", jwt);
}

export function logout() {
  localStorage.removeItem("user");
  localStorage.removeItem("auth.jwt");
}
