import React from "react";
import { Controller } from "react-hook-form";
import { FormControl, FormHelperText, InputLabel, MenuItem, Select } from '@mui/material';
  
export const DDInputSelect = ({ name, control, label, disabled = false, type = 'text', sx, options }) => {  
  
    return (
        <Controller 
          name={name}
          control={control}           
          render={({
            field: {onChange, value},
            fieldState: {error}
          }) => (
            <FormControl fullWidth error={!!error}>
              <InputLabel id="select-template">{label}</InputLabel>
              <Select                           
                labelId='select-template'
                label="Template"         
                sx={sx}                                      
                onChange={onChange}
                value={value}                                        
                disabled={disabled}                 
                children={options}           
              >                       
              </Select>
              <FormHelperText>{error?.message}</FormHelperText>
            </FormControl>
          )}
        />
    );
};
