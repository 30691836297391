import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {useForm} from "react-hook-form";
import {
    Alert,
    Box,
    Button,
    CssBaseline,    
    Grid,    
    Paper
} from "@mui/material";

import {makeStyles} from "@mui/styles"
import {yupResolver} from "@hookform/resolvers/yup";
import {DDInputText} from "../base/ddform/DDInputText";
import {setAuth} from "../base/authGuard";

import logo from "../assets/gb-logo.png";
import * as CallCore from "../hook/callCore";
import * as Yup from "yup";

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        height: "100vh",
        flexDirection: "row",        
        justifyContent: "center",
    },
    card: {
        display: "flex",
        flex: "auto"
    },
    form: {
        width: "100%"
    }

}));

const defaultValues = { 
    userLogin: "",
    userPasswd: "",    
    expPasswd: "",      
  };

function Login() {

    const schema = Yup.object().shape({       
        userLogin: Yup.string().trim().required("Campo obbligatorio").max(50, "Lunghezza massima possibile 50 caratteri"),
        userPasswd: Yup.string().trim().required("Campo obbligatorio").max(50, "Lunghezza massima possibile 50 caratteri")      
    });

    const classes = useStyles();
    const [error, setError] = React.useState();
    const [msg, setMsg] = React.useState();

    const { handleSubmit, control } = useForm({ 
          defaultValues,  
          mode: "all",
          reValidateMode: "onChange",
          criteriaMode: "firstError",
          resolver: yupResolver(schema) 
      });

    function onSubmit(data) {        
        setError(false);
        setMsg(null);

        CallCore.login(data).then((res) => {
          if(!res['ret']) {
              setError(true);
              setMsg(res['msg']);
          } else {
              setAuth(res.user, res.jwt);
              window.location.pathname = '/dashboard';
          }
        });        
    }

    const location = useLocation();
    const navigate = useNavigate();

    React.useEffect(() => {
      if(location.state && location.state.error) {
        setError(true);
        setMsg(location.state.msg);
        navigate('/login', {replace: true});
      }
    }, [navigate, location.state]);

    return (
        <Grid container className={classes.root}>
            <CssBaseline enableColorScheme/>
            <Grid item xs={12} md={6} lg={5} xl={3} align="center">                                                 
                <Paper                    
                    onSubmit={handleSubmit}
                    component="form"
                    sx={{                        
                        display: "flex",                        
                        flexDirection: "row",  
                        flexWrap: "wrap",                              
                        rowGap: "24px",        
                        p: "20px",                       
                        width: "100%",                        
                    }}
                >            
                <Box component="img"   
                    sx={{
                    display: "flex",
                    flexDirection: "row",  
                    flexWrap: "wrap",     
                    margin: "auto"                           
                    }}         
                    alt="Logo"
                    src={logo}
                />                    
                    <DDInputText name="userLogin" control={control} label="Username *"/>
                    <DDInputText name="userPasswd" control={control} label="Password *" type="password"/>                            
                    <Button onClick={handleSubmit(onSubmit)} variant={"contained"} type={"submit"} sx={{width: "100%"}}>
                        Login
                    </Button>
                </Paper>
                {error ? (<Alert severity="warning" sx={{marginTop: 2}}>{msg}</Alert>) : ""}
            </Grid>
        </Grid>
    );

}

export default Login;