import {Navigate, Outlet} from "react-router";
import {isAuth} from "./authGuard";

const userAuth = () => {
    const user = {loggedIn: isAuth()};
    return user && user.loggedIn;
};

const ProtectedRoutes = () => {
    const isAuth = userAuth();
    return isAuth ? <Outlet/> : <Navigate to="/login"/>;
};

export default ProtectedRoutes;